import {
  SmpcResponse,
  SmpcDetails,
  Artifact,
  SmpcUploadFileResponse,
  ProcessedArtifact,
  SmpcDetailsUpdate,
} from '@/models/smpc';
import {
  ApiError,
  Status,
} from '@/models/auth';
import http from '../utils/http-client';

const toTitleCase = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

class SmPCService {
  static async showSmpc(id: string): Promise<SmpcDetails> {
    const response: unknown = await http.get(`api/smpc/show/${id}`);

    return response as SmpcDetails;
  }

  static async list(params?: any): Promise<any> {
    const response = await http.get('/api/smpc/list', { params });

    return response.data;
  }

  static async listSmpcs(status?: string, name?: string, page?: number): Promise<SmpcResponse> {
    const {
      data,
      total,
      per_page: perPage,
      current_page: currentPage,
    }: SmpcResponse = await http.get(
      `/api/smpc/list?status=${status}&smpc_name=${name}&page=${page}`,
    );

    return {
      data,
      total,
      per_page: perPage,
      current_page: currentPage,
    };
  }

  static async deleteSmpc(id: string): Promise<any | ApiError> {
    let response: any = {} as any;

    try {
      response = await http.delete(`/api/smpc/delete/${id}`);
    } catch (exception: any) {
      const error: ApiError = exception.response?.data?.error;
      if (error) {
        if (error.status_code === Status.API_ERROR) {
          return error;
        }

        return {
          message: 'Unexpected error occured',
          status_code: Status.ERROR,
        };
      }
    }

    return response;
  }

  static async updateSmpc(id: string, details: SmpcDetailsUpdate): Promise<any | ApiError> {
    let response: any = {} as any;

    try {
      response = await http.post(`api/smpc/update/${id}`, details);
    } catch (exception: any) {
      const status = exception?.response?.status;

      if (status !== Status.SUCCESS) {
        const error = exception.response?.data?.error;

        if (!error) {
          return {
            message: 'Unexpected error occured',
            status_code: Status.ERROR,
          };
        }

        return error;
      }
    }

    return response;
  }

  static async approveSmpc(id: string): Promise<any | ApiError> {
    let response: any = {} as any;

    try {
      response = await http.post(`api/smpc/approve/${id}`);
    } catch (exception: any) {
      const status = exception?.response?.status;

      if (status !== Status.SUCCESS) {
        const error = exception.response?.data?.error;

        if (!error) {
          return {
            message: 'Unexpected error occured',
            status_code: Status.ERROR,
          };
        }

        return error;
      }
    }

    return response;
  }

  static async unapproveSmpc(id: string): Promise<any | ApiError> {
    let response: any = {} as any;

    try {
      response = await http.post(`api/smpc/unapprove/${id}`);
    } catch (exception: any) {
      const status = exception?.response?.status;

      if (status !== Status.SUCCESS) {
        const error = exception.response?.data?.error;

        if (!error) {
          return {
            message: 'Unexpected error occured',
            status_code: Status.ERROR,
          };
        }

        return error;
      }
    }

    return response;
  }

  static async getArtifact(id: string): Promise<Artifact> {
    const response: unknown = await http.get(`api/smpc/artifact/show/${id}`);

    return response as Artifact;
  }

  static async getMedia(id: string): Promise<string> {
    const response: unknown = await http.get(`api/smpc/media/show/${id}`);

    const media: Blob = new Blob([response as string], { type: 'application/pdf;base64' });

    const mediaURL: string = URL.createObjectURL(media);

    return mediaURL;
  }

  static async getMediaPdf(id: string): Promise<string> {
    const response: any = await http.get(`api/smpc/media/show/${id}/pdf`, { responseType: 'blob' });

    const mediaURL: string = URL.createObjectURL(response);

    return mediaURL;
  }

  static async uploadImage(file: File): Promise<SmpcUploadFileResponse> {
    const formData = new FormData();

    formData.append('file', file);

    const uploadedFile: SmpcUploadFileResponse = await http.post('api/smpc/media/image/upload', formData);

    return uploadedFile;
  }

  static async uploadSmpc(file: any): Promise< ProcessedArtifact[] | ApiError> {
    const formData = new FormData();

    formData.append('file', file);

    let processedArtifacts: ProcessedArtifact[] = [];

    try {
      const uploadedFile: SmpcUploadFileResponse = await http.post('api/smpc/media/file/upload', formData);

      const artifact = {
        name: toTitleCase(uploadedFile.title.split('.pdf')[0]),
        key: uploadedFile.title.split('.pdf')[0],
        source: uploadedFile.id,
        template_version: file.template_version,
      };

      const createArtifactId: string = await http.post('api/smpc/artifact/create', artifact);

      processedArtifacts = await http.post(`api/smpc/artifact/process/${createArtifactId}`);
    } catch (exception: any) {
      const status = exception?.response?.status;

      if (status !== Status.SUCCESS) {
        const error = exception.response?.data?.error;

        if (!error) {
          return {
            message: 'Unexpected error occured',
            status_code: Status.ERROR,
          };
        }

        return error;
      }
    }

    return processedArtifacts;
  }
}

export default SmPCService;
