
import { defineComponent } from 'vue';
import Pagination from '@/components/pagination/Pagination.vue';
import UiSearch from '@/components/form/UiSearch.vue';
import Dropdown from '@/components/search/Dropdown.vue';
import UiOverlay from '@/components/form/UiOverlay.vue';
import UiButton from '@/components/clickables/UiButton.vue';
import Modal from '@/components/modals/Modal.vue';
import PreLoader from '@/components/PreLoader/PreLoader.vue';
import SmPCService from '@/services/SmPCService';
import {
  ProcessedArtifact,
  Smpc,
  UploadFailed,
  UploadSuccess,
} from '@/models/smpc';
import { ApiError } from '@/models/auth';
import UiCombobox from '@/components/form/UiCombobox.vue';

export default defineComponent({
  name: 'ListSmpc',
  components: {
    Pagination,
    UiSearch,
    Dropdown,
    Modal,
    UiOverlay,
    UiButton,
    PreLoader,
    UiCombobox,
  },
  data: () => ({
    size: 1 as number,
    total: 0 as number,
    currentPage: 1 as number,
    smpcsList: [] as Smpc[],
    isLoaded: false as boolean,
    smpcStatus: '' as string,
    smpcName: '' as string,
    state: false as boolean,
    filesSelected: [] as any[],
    filesNotSelected: [] as UploadFailed[],
    smpcsUploaded: [] as UploadSuccess[],
    smpcsNotUploaded: [] as UploadFailed[],
    isFileUploading: false as boolean,
    onDeleteSmpcId: '',
    confirmationModal: false,
    isEmpty: false,
    searchTimeoutId: 0,
    isDragOver: false,
    isPageLoaded: false,
    errorMessages: [] as any,
  }),
  async created() {
    this.fetchSmpcs();
  },
  watch: {
    async smpcName(val) {
      if (!/\S/.test(val) && val !== '') {
        return;
      }

      window.clearTimeout(this.searchTimeoutId);

      this.searchTimeoutId = window.setTimeout(async () => {
        this.isEmpty = false;
        this.currentPage = 1;
        this.fetchSmpcs();
      }, 300);
    },
  },
  methods: {
    getExtentionIcon(file: UploadSuccess | UploadFailed): string {
      if (this.getFileType(file.type, file.name) === 'application/pdf') {
        return 'file-pdf';
      }
      if (this.getFileType(file.type, file.name) === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || this.getFileType(file.type, file.name) === 'application/msword' || this.getFileType(file.type, file.name) === 'docx' || this.getFileType(file.type, file.name) === 'doc') {
        return 'file-word';
      }
      return 'file';
    },
    clearState(): void {
      this.filesSelected = [] as any[];
      this.filesNotSelected = [] as UploadFailed[];
      this.smpcsUploaded = [] as UploadSuccess[];
      this.smpcsNotUploaded = [] as UploadFailed[];
    },
    scrollBack(): void {
      const source = this.$refs['smpc-list-anchor'] as HTMLElement;
      const top = source.offsetTop - 10;
      window.scroll({
        top,
        behavior: 'smooth',
      });
    },
    editSmpc(id: string): void {
      this.$router.push({ name: 'EditSmpc', params: { id } });
    },
    onDeleteHandler(id: string): void {
      this.onDeleteSmpcId = id;
      this.confirmationModal = true;
    },
    async onDeleteConfirmed(): Promise<void> {
      this.isLoaded = false;
      const response = await SmPCService.deleteSmpc(this.onDeleteSmpcId);

      const apierror: ApiError = response as ApiError;

      this.confirmationModal = false;

      if (apierror?.message) {
        this.isLoaded = true;
        this.$toast.show(`Error: ${apierror.message}`, 3000);
      } else {
        this.$toast.show('SmPC deleted succefully', 3000);
        this.onDeleteSmpcId = '';
        this.fetchSmpcs();
      }
    },
    toLocalDate(date: string): string {
      return new Date(date.replace(/-/g, '/')).toLocaleDateString();
    },
    async fetchSmpcs(): Promise<void> {
      this.isLoaded = false;
      this.smpcsList = [];
      this.total = 0;

      const service = await SmPCService.listSmpcs(
        this.smpcStatus,
        this.smpcName,
        this.currentPage,
      );
      if (service.data.length > 0) {
        this.smpcsList = service.data;
        this.size = Math.ceil(service.total / service.per_page);
        this.total = service.total;
        this.isEmpty = false;
      } else {
        this.isEmpty = true;
      }
      this.isLoaded = true;
      this.isPageLoaded = true;
    },
    async onStatusChange(status: string): Promise<void> {
      this.smpcStatus = status;
      this.fetchSmpcs();
    },
    onDragHandler(event: Event): void {
      event.preventDefault();

      const eventType = event.type;

      this.isDragOver = eventType === 'dragover';
    },
    onDropFileHandler(event: DragEvent): void {
      this.onDragHandler(event as Event);
      event.preventDefault();

      if (event.dataTransfer) {
        if (event.dataTransfer.items) {
          for (let i = 0; i < event.dataTransfer.items.length; i += 1) {
            if (event.dataTransfer.items[i].kind === 'file') {
              const file = event.dataTransfer.items[i].getAsFile() as any;
              this.checkFileForErrors(file);
            }
          }
        } else {
          for (let i = 0; i < event.dataTransfer.files.length; i += 1) {
            const file = event.dataTransfer.files[i] as any;
            this.checkFileForErrors(file);
          }
        }
      }
    },
    getFileType(type: string, name: string): string {
      if (type === '') {
        return name.substring(name.lastIndexOf('.') + 1, name.length);
      }

      return type;
    },
    checkFileForErrors(file: any) {
      const sizeInMB = file.size / (1024 * 1024);
      let error = 'Only PDF, DOCX, DOC files allowed';
      if (!this.filesSelected.some((fl) => (
        fl.name === file.name
        && fl.size === file.size
        && fl.type === this.getFileType(file.type, file.name)
      ))) {
        if (file.type === 'application/pdf' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || file.type === 'application/msword' || this.getFileType(file.type, file.name) === 'docx' || this.getFileType(file.type, file.name) === 'doc') {
          if (sizeInMB < this.fileUploadSize) {
            this.onFileUpload(file);
          } else {
            error = `File with size ${sizeInMB.toFixed(2)}mb not accepted`;
            this.filesNotSelected.push({
              name: file.name,
              type: this.getFileType(file.type, file.name),
              size: file.size,
              error,
            });
          }
        } else if (sizeInMB > this.fileUploadSize) {
          error += `, File with size ${sizeInMB.toFixed(2)}mb not accepted`;
          this.filesNotSelected.push({
            name: file.name,
            type: this.getFileType(file.type, file.name),
            size: file.size,
            error,
          });
        }
      } else {
        error = 'Duplicate file not allowed';
        this.filesNotSelected.push({
          name: file.name,
          type: this.getFileType(file.type, file.name),
          size: file.size,
          error,
        });
      }
    },
    onClickFileHandler(event: Event): void {
      event.preventDefault();
      const inputElement = event.target as HTMLInputElement;
      const files = inputElement.files as FileList;
      for (let index = 0; index < files.length; index += 1) {
        const file = files[index] as any;
        this.checkFileForErrors(file);
      }
      inputElement.value = '';
    },
    async onFileUpload(file: any): Promise<void> {
      this.isFileUploading = true;

      const extendedFile = {
        name: file.name,
        size: file.size,
        type: file.type,
        version: {},
        file,
      };

      this.filesSelected.push(extendedFile);

      this.isFileUploading = false;
    },
    async onSmpcUpload(): Promise<void> {
      const temp = [...this.filesSelected];

      this.errorMessages = [];

      for (let i = 0; i < temp.length; i += 1) {
        if (!temp[i]?.version?.value) {
          this.errorMessages[i] = 'Version field is required';
        }
      }

      if (this.errorMessages.length) return;

      this.isFileUploading = true;

      await temp.reduce(async (promise, file) => {
        await promise;

        try {
          const specs = file.file;
          specs.template_version = file.version.value;

          const response = await SmPCService.uploadSmpc(specs);

          const apierror: ApiError = response as ApiError;
          const artifacts: ProcessedArtifact[] = response as ProcessedArtifact[];

          if (apierror?.message) {
            this.smpcsNotUploaded.push({
              name: file.name,
              type: this.getFileType(file.type, file.name),
              size: file.size,
              error: apierror.message,
            });
          } else {
            artifacts.forEach((filename: ProcessedArtifact) => {
              this.smpcsUploaded.push({
                name: filename.name,
                type: this.getFileType(file.type, file.name),
                size: file.size,
              });
            });

            this.fetchSmpcs();
          }

          this.filesSelected.forEach((targetFile, position) => {
            if (file.name === targetFile.name) {
              this.filesSelected.splice(position, 1);
            }
          });
        } catch (error: any) {
          this.isFileUploading = false;
          this.smpcsNotUploaded.push({
            name: file.name,
            type: this.getFileType(file.type, file.name),
            size: file.size,
            error: error?.error?.message?.length || 'Server error',
          });
        }
      }, Promise.resolve());

      this.isFileUploading = false;
    },
    onRemoveSelectedFile(index: number) {
      if (this.errorMessages[index]) {
        this.errorMessages.splice(index, 1);
      }

      this.isFileUploading = true;
      const temp = [...this.filesSelected];
      this.$nextTick(() => {
        temp.splice(index, 1);
        this.filesSelected = [...temp];
        this.isFileUploading = false;
      });
    },
    paginationHandler(): void {
      this.fetchSmpcs();
    },
  },
  computed: {
    canSaveFiles(): boolean {
      return this.filesSelected.length > 0 && !this.isFileUploading;
    },
    currentItems(): number {
      const currentTotal = 10 * this.currentPage;
      return currentTotal >= this.total ? this.total : currentTotal;
    },
    fileUploadSize() {
      return Number(process.env.VUE_APP_FILE_UPLOAD_SIZE);
    },
    smpcTemplateVersions() {
      return (process.env.VUE_APP_SMPC_TEMPLATE_VERSIONS || '').split(', ');
    },
    fileVersions(): any {
      return this.smpcTemplateVersions.map((el: string) => ({
        title: el,
        value: el,
      }));
    },
  },
});
