import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2fffd02c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "modal"
}
const _hoisted_2 = { class: "modal-header" }
const _hoisted_3 = { class: "modal-title" }
const _hoisted_4 = { class: "modal-actions" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "modal-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.state)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(`modal-body ${_ctx.size}`)
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("button", {
                class: "action-button outlined",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleState(false)))
              }, "Close"),
              _createElementVNode("button", {
                class: "action-button filled",
                disabled: _ctx.disabled,
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.saveState && _ctx.saveState(...args)))
              }, _toDisplayString(_ctx.actionText), 9, _hoisted_5)
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ])
        ], 2)
      ]))
    : _createCommentVNode("", true)
}