
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Modal',
  props: {
    title: {
      type: String,
      default: 'Title',
      required: true,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
    state: {
      type: Boolean,
      required: true,
    },
    actionText: {
      type: String,
      default: 'Save',
      required: true,
    },
    size: {
      type: String,
      default: 'big',
      required: true,
    },
  },
  emits: ['update:state', 'save:state'],
  methods: {
    toggleState(state: boolean) {
      this.$emit('update:state', state);
    },
    saveState() {
      this.$emit('save:state');
    },
  },
});
